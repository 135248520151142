
import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {
    AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import {
    catchError, debounceTime, switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem
} from '@angular/cdk/drag-drop';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Observable, of, Subject } from 'rxjs';
import { LogTemplatesService } from '@app/shared/log-templates/log-templates.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import {
    LogTemplate,
    LogTemplateColumn,
    LogTemplateDetail,
    LogTemplateDetailType,
    LogEntryTypes,
    LogTemplateType
} from '../../../../shared/models';
import { MESSAGES, REGEX } from '../../../../core/constants';
import { notBlank } from '../../../../core/form-validators';
import { ModalsService } from '../../../../shared/modal-helper/modals.service';
import { MultiChoiceFormComponent } from '../multi-choice-form/multi-choice-form.component';
import { MultiChoiceData } from '../multi-choice-form/multi-choice-form.component.types';
import template from './log-template-form.component.html';
import styles from './log-template-form.component.scss';

@Component({
    selector: 'log-template-form',
    template,
    styles: [String(styles)]
})
export class LogTemplateFormComponent implements OnInit, OnChanges {
    @Input() logTemplate: LogTemplate;
    @Input() canEdit = true;
    @Output() submit = new EventEmitter<{ logTemplate: Partial<LogTemplate> }>();

    @ViewChild('logColumnsRef', { static: false }) logColumnsEl: ElementRef;

    dragValue: string;
    items: any[]
    loading = true;
    logTemplateId: string;
    isLatestVersion = true;
    validationErrShortMessage = MESSAGES.validShortNameMessage;
    validationErrLongMessage = MESSAGES.validExtremelyLongTextMessage;
    validationErrMessage = MESSAGES.validNameMessage;
    validationErrBlankMessage = MESSAGES.invalidBlankMessage;
    validationErrTemplateNameUnique = MESSAGES.nonUniqueValue('template', 'name');
    readonly defaultDetails: LogTemplateDetail[] = [
        { name: 'Study Name', type: LogTemplateDetailType.TEXT },
        { name: 'Unique Protocol Number', type: LogTemplateDetailType.TEXT },
        { name: 'Principal Investigator', type: LogTemplateDetailType.TEXT },
        { name: 'IRB Number', type: LogTemplateDetailType.TEXT },
        { name: 'Sponsor', type: LogTemplateDetailType.TEXT }
    ];

    readonly columnTypes = [
        { value: 'text', displayValue: 'Text' },
        { value: 'team member', displayValue: 'Team Member' },
        { value: 'date', displayValue: 'Date' },
        { value: 'signature', displayValue: 'Signature' },
        { value: 'dropdown multi-select', displayValue: 'Dropdown Multi-select' },
        { value: 'dropdown single-select', displayValue: 'Dropdown Single-select' }

    ];

    @ViewChild('dropListContainer', { static: false }) dropListContainer?: ElementRef;
    readonly MAX_COLUMNS = 20;

    logTemplateForm = this.fb.group({
        name: [{ value: '', disabled: !this.canEdit }, [
            Validators.required,
            Validators.pattern(REGEX.names),
            notBlank,
            Validators.maxLength(50)
        ]],
        details: this.fb.array([], [
            Validators.required
        ]),
        legend: [{ value: '', disabled: !this.canEdit }, [
            Validators.pattern(REGEX.extremelyLongText),
            Validators.maxLength(10000),
            notBlank
        ]],
        information: [{ value: '', disabled: !this.canEdit }, [
            Validators.pattern(REGEX.extremelyLongText),
            Validators.maxLength(10000),
            notBlank
        ]],
        columns: this.fb.array([], [
            Validators.minLength(1),
            Validators.maxLength(this.MAX_COLUMNS)
        ]),
        comment: [{ value: '', disabled: !this.canEdit }, [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]]
    });

    multiChoiceColumnOptionValidators = [
        Validators.required,
        Validators.maxLength(100),
        notBlank
    ];

    singleChoiceColumnOptionValidators = [
        Validators.required,
        Validators.maxLength(100),
        notBlank
    ];

    dragDropInfo: { dragIndex: any; dropIndex: any; };
    dropListReceiverElement: any;
    private destroy$ = new Subject<void>();

    constructor(
        private $state: StateService,
        private currentSessionService: CurrentSessionService,
        private fb: FormBuilder,
        private Modals: ModalsService,
        private logTemplatesService: LogTemplatesService,
        private Notifications: NotificationsService
    ) { }

    ngOnInit(): void {
        const stateParams = this.$state.params;
        this.logTemplateId = stateParams.logTemplateId;
        this.logTemplateForm.controls.name.valueChanges.pipe(
            debounceTime(300),
            switchMap(() => this.templateNameUniqueValidator$()),
            tap((isUnique) => {
                if (isUnique === false) {
                    this.logTemplateForm.controls.name.setErrors({ notUnique: true });
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.logTemplate) {
            this.initializeForm(changes.logTemplate.currentValue);
        }
    }

    templateNameUniqueValidator$(): Observable<boolean> | null {
        const teamId = this.currentSessionService.getCurrentTeam().id;
        const { value } = this.logTemplateForm.controls.name;
        if (!value) {
            return of(null);
        }

        return this.logTemplatesService.isLogTemplateNameUnique$(teamId, value, LogTemplateType.ELOG, this.logTemplateId).pipe(
            catchError(() => {
                this.Notifications.error('Error checking if template name is unique.');
                return of(false);
            })
        );
    }

    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = this.validationErrBlankMessage;
                break;
            case formControl.errors?.notUnique:
                message = this.validationErrTemplateNameUnique;
                break;
            default:
                message = this.validationErrShortMessage;
                break;
        }

        return { message };
    }

    get columns() {
        return (this.logTemplateForm.get('columns') as FormArray);
    }

    onDrop(event: CdkDragDrop<string[]>) {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data,
                event.previousIndex, event.currentIndex);
            const array = this.columns.value;
            moveItemInArray(array, event.previousIndex, event.currentIndex);
            this.columns.setValue(array);
            this.logTemplateForm.markAsDirty();
        }
        else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    onSave(): void {
        const { details, columns } = this.logTemplateForm.value;
        const mappedDetails = details && details.map((d) => ({
            name: d.name,
            type: d.type,
            ...d.options && d.options.length && { options: d.options }
        }));
        const mappedColumns = columns && columns.map((c) => ({
            name: c.name,
            type: c.type,
            ...c.headerOptions && c.headerOptions.length && { headerOptions: c.headerOptions },
            ...c.type === LogEntryTypes.multiSelect && c.multiselectColumnOptions
            && c.multiselectColumnOptions.length && { multiselectColumnOptions: c.multiselectColumnOptions },
            ...c.type === LogEntryTypes.multiSelect && c.multiselectColumnOptions
                && c.multiselectColumnOptions.length
                && { isNumberIdentifier: c.isNumberIdentifier !== null ? c.isNumberIdentifier : true },
            ...c.type === LogEntryTypes.singleSelect && c.singleselectColumnOptions
            && c.singleselectColumnOptions.length && { singleselectColumnOptions: c.singleselectColumnOptions }
        }));
        const valueToSubmit = {
            ...this.logTemplateForm.value,
            details: mappedDetails || [],
            columns: mappedColumns || []
        };

        if (!this.loading && !this.logTemplateForm.pristine && this.logTemplateForm.valid) {
            this.submit.emit(valueToSubmit);
            this.logTemplateForm.markAsPristine();
        }
    }

    initializeForm(logTemplate: LogTemplate): void {
        this.ctrlsDetails.clear();
        this.ctrlsColumns.clear();

        if (logTemplate && Object.keys(logTemplate).length) {
            this.logTemplate.details.forEach(this.addDetail.bind(this));
            this.logTemplate.columns.forEach(this.addColumn.bind(this));
            this.logTemplateForm.patchValue({ ...logTemplate, comment: '' });
            this.logTemplateForm.markAsPristine();
            this.isLatestVersion = logTemplate.isLatestVersion;
            this.loading = false;
            return;
        }
        this.defaultDetails.forEach(this.addDetail.bind(this));
        this.addColumn();
        this.loading = false;
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.logTemplateForm.controls;
    }

    get ctrlsDetails(): FormArray {
        return this.ctrls.details as FormArray;
    }

    get ctrlsColumns(): FormArray {
        return this.ctrls.columns as FormArray;
    }

    trimValue(control: FormControl) {
        const value = control.value.trim();
        control.setValue(value);
    }

    canAddDetail(): boolean {
        const details = this.ctrlsDetails.controls;
        return details.length <= this.MAX_COLUMNS && this.canEdit;
    }

    addDetail(detail: LogTemplateDetail = {
        type: LogTemplateDetailType.TEXT,
        name: '',
        options: []
    }): void {

        const group = this.fb.group({
            name: [detail.name, [
                Validators.required,
                Validators.pattern(REGEX.names),
                notBlank,
                Validators.maxLength(50)
            ]],
            type: [detail.type, [Validators.required]],
            options: this.fb.array(detail.options || [], [])
        });
        this.ctrlsDetails.push(group);
    }

    addMultichoiceDetail(): void {
        const multiChoiceModalRef = this.Modals.show(MultiChoiceFormComponent, {
            initialState: {
                isForColumn: false
            }
        });

        multiChoiceModalRef.content.save
            .pipe(take(1))
            .subscribe((data: MultiChoiceData) => {
                this.addDetail({
                    ...data,
                    type: LogTemplateDetailType.SELECT
                });
                this.logTemplateForm.markAsDirty();
            });

        multiChoiceModalRef.content.error
            .subscribe((message: string) => {
                this.Notifications.error(message);
            });
    }

    editMultichoiceDetail(detailFormGroup: FormGroup): void {
        const { name, options } = detailFormGroup.value;
        const multiChoiceModalRef = this.Modals.show(MultiChoiceFormComponent, {
            initialState: {
                isForColumn: false,
                data: { name, options }
            }
        });

        multiChoiceModalRef.content.save
            .pipe(take(1))
            .subscribe((data: MultiChoiceData) => {
                const detailFormGroupOptions = detailFormGroup.controls.options as FormArray;
                detailFormGroupOptions.clear();
                data.options.forEach((opt) => detailFormGroupOptions.push(new FormControl(opt)));

                detailFormGroup.controls.name.setValue(data.name);
                this.logTemplateForm.markAsDirty();
            });

        multiChoiceModalRef.content.error
            .subscribe((message: string) => {
                this.Notifications.error(message);
            });
    }

    addMultichoiceColumn(): void {
        const multiChoiceModalRef = this.Modals.show(MultiChoiceFormComponent, {
            initialState: {
                isForColumn: true
            }
        });

        multiChoiceModalRef.content.save
            .pipe(take(1))
            .subscribe((data: MultiChoiceData) => {
                this.addColumn({
                    name: data.name,
                    headerOptions: data.options,
                    type: this.columnTypes[0].value
                });
                this.logTemplateForm.markAsDirty();
            });

        multiChoiceModalRef.content.error
            .subscribe((message: string) => {
                this.Notifications.error(message);
            });
    }

    editMultichoiceColumn(colFormGroup: FormGroup): void {
        const multiChoiceModalRef = this.Modals.show(MultiChoiceFormComponent, {
            initialState: {
                isForColumn: true,
                data: {
                    name: colFormGroup.value.name,
                    options: colFormGroup.value.headerOptions
                }
            }
        });

        multiChoiceModalRef.content.save
            .pipe(take(1))
            .subscribe((data: MultiChoiceData) => {
                const colFormGroupHeaderOptions = colFormGroup.controls.headerOptions as FormArray;
                colFormGroupHeaderOptions.clear();
                data.options.forEach((opt) => colFormGroupHeaderOptions.push(new FormControl(opt)));

                colFormGroup.controls.name.setValue(data.name);
                this.logTemplateForm.markAsDirty();
            });

        multiChoiceModalRef.content.error
            .subscribe((message: string) => {
                this.Notifications.error(message);
            });
    }

    canRemoveColumn(): boolean {
        return this.isLatestVersion && this.ctrlsColumns.controls.length > 1 && this.canEdit;
    }

    canAddColumn(): boolean {
        const columns = this.ctrlsColumns.controls;
        return columns.length && columns.length < this.MAX_COLUMNS && this.canEdit;
    }

    handleAddColumnClick(): void {
        // if adding column from template, focus on the element once it is added
        this.addColumn();
        setTimeout(() => this.lastFormColumn(this.ctrlsColumns.length), 0);
    }

    private addColumn(column?: LogTemplateColumn): void {
        let values: LogTemplateColumn = {
            name: '',
            type: this.columnTypes[0].value,
            headerOptions: [],
            multiselectColumnOptions: [],
            singleselectColumnOptions: [],
            isNumberIdentifier: null
        };
        if (column) {
            values = column;
        }
        const group = this.fb.group({
            name: [values.name, [
                Validators.required,
                Validators.pattern(REGEX.names),
                Validators.maxLength(50),
                notBlank
            ]],
            type: [values.type, [Validators.required]],
            headerOptions: this.fb.array(values.headerOptions || [], []),
            multiselectColumnOptions: this.fb.array(values.multiselectColumnOptions || [], []),
            singleselectColumnOptions: this.fb.array(values.singleselectColumnOptions || [], []),
            isNumberIdentifier: values.isNumberIdentifier
        });
        return this.ctrlsColumns.push(group);
    }

    private lastFormColumn(formCtrlNumber: number): void {
        // since logColumnsEl wont be accessible until ngAfterViewInit, doing an `if` check for safety
        if (this.logColumnsEl) {
            const el = this.logColumnsEl.nativeElement.children[formCtrlNumber - 1]
                .children[0].children[1].children[0] as HTMLElement;
            el.querySelector('input').focus();
        }
    }

    setColumnType(ctrl: FormGroup, type: string): void {
        ctrl.patchValue({ type: type.trim().toLowerCase() });
        this.logTemplateForm.markAsDirty();
    }

    removeAt(ctrls: FormArray, index: number): void {
        ctrls.removeAt(index);
        this.logTemplateForm.markAsDirty();
    }

    isItemSelected(item: FormGroup, type: string): boolean {
        if (item.controls.type.value === type) {
            this.dragValue = type;
        }
        return item.controls.type.value === type;
    }

    onSingleSelectColumnOptionsFormUpdated(singleselectColumnOptions: string[], columnIndex: number) {
        const control = (this.ctrlsColumns.controls[columnIndex] as FormGroup).controls.singleselectColumnOptions as FormArray;
        let controlIsCleared = false;

        singleselectColumnOptions.forEach((option) => {
            if (!controlIsCleared) {
                control.clear();
                controlIsCleared = true;
            }

            control.push(new FormControl(option, this.singleChoiceColumnOptionValidators));
        });

        this.logTemplateForm.markAsDirty();
    }

    onMultiselectColumnOptionsFormUpdated(multiselectColumnOptions: string[], columnIndex: number) {
        const control = (this.ctrlsColumns.controls[columnIndex] as FormGroup).controls.multiselectColumnOptions as FormArray;
        let controlIsCleared = false;

        multiselectColumnOptions.forEach((option) => {
            if (!controlIsCleared) {
                control.clear();
                controlIsCleared = true;
            }

            control.push(new FormControl(option, this.multiChoiceColumnOptionValidators));
        });

        this.logTemplateForm.markAsDirty();
    }

    onMultiselectColumnIdentifierUpdated(isNumberIdentifier: boolean, columnIndex: number) {
        const control = (this.ctrlsColumns.controls[columnIndex] as FormGroup).controls.isNumberIdentifier;
        control.patchValue(isNumberIdentifier);
        this.logTemplateForm.markAsDirty();
    }

}
